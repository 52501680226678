import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from 'src/context/Auth';  // Use the correct context import

const Logo = (props) => {
  const auth = useContext(AuthContext);  // Use the correct context

  return (
    <Link to={auth?.userLoggedIn ? '/dashboard' : '/'}>
      <img src="/images/logo.jpeg" alt="Logo" {...props} height="80px" />
    </Link>
  );
};

export default Logo;