import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
// import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },
  {
    exact: true,
    path: "/forget",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Forget")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/ChangePassword")),
  },
  // {
  //   exact: true,
  //   path: "/",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Home")),
  // },
  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DashdoardHome")),
  },

  {
    exact: true,
    guard: true,
    path: "/static-content-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/ContentManagement/StaticContentManagement"
      )
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/edit-content",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContentManagement/EditContent")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/add-content",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContentManagement/AddContent")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-content",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContentManagement/ViewContent")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/faqs-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/Faqs")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/view-faq",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/ViewFaq")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/add-faq",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/AddFaq")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/exchange",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Exchange")),
  },
  {
    exact: true,
    guard: true,
    path: "/exchange-first",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ExchangeMain")),
  },
  {
    exact: true,
    guard: true,
    path: "/direct",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Direct")),
  },
  {
    exact: true,
    guard: true,
    path: "/direct-plan",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DirectFirst")),
  },
  {
    exact: true,
    guard: true,
    path: "/intra",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Intra")),
  },
  {
    exact: true,
    guard: true,
    path: "/triangular",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Triangular")),
  },
  {
    exact: true,
    guard: true,
    path: "/loop",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Loop")),
  },
  {
    exact: true,
    guard: true,
    path: "/transactions-history",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Transection")),
  },
  {
    exact: true,
    guard: true,
    path: "/subscription",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Subscription")),
  },
  {
    exact: true,
    guard: true,
    path: "/invite",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Invite")),
  },
  {
    exact: true,
    guard: true,
    path: "/invite-view",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/InviteView")),
  },
  {
    exact: true,
    guard: true,
    path: "/invite-edit",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/InviteEdit")),
  },
  {
    exact: true,
    guard: true,
    path: "/setting",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Setting/BotSetting")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/my-wallet",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Wallet/Mywallet")),
  },
  {
    exact: true,
    guard: true,
    path: "/pair-details",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/PairDetails")),
  },
  {
    exact: true,
    guard: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Profile")),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/EditProfile")),
  },
  {
    exact: true,
    guard: true,
    path: "/notification",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/Notification")),
  },
  {
    exact: true,

    path: "/privacy-policy",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Static/Privacy")),
  },
  {
    exact: true,
    guard: true,
    path: "/FAQ",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/FaqSection")),
  },
  {
    exact: true,

    path: "/term-and-condition",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Static/Terms")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    path: "/select-coin",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Strategy/coinSelection")),
  },
  {
    exact: true,
    path: "/strategy",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Strategy/index")),
  },
  {
    exact: true,
    path: "/trading-features",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Strategy/tradingFeatures")),
  },
  {
    exact: true,
    path: "/prediction",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/prediction/Prediction")),
  },
  {
    exact: true,
    path: "/prediction-history",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/prediction/PredictionHistory")
    ),
  },
  {
    exact: true,
    path: "/leaderboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/prediction/Leaderboard")),
  },
  {
    exact: true,
    path: "/register/:inviteCode?",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/signup.js")),
  },

  {
    exact: true,
    guard: true,
    path: "/subscriptions",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Subscriptions/Subscriptions")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/Arbitrage",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Arbitrage.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/ExecutionStrategy",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ExecutionStrategy.js")
    ),
  },
  {
    exact: true,
    path: "/user-agreement",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Static/Userservice.js")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
