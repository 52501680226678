import React, { createContext, useEffect, useState } from "react";
import { injected } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import DataLoader from "src/component/DataLoader";

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

export default function AuthProvider(props) {
  const { activate, account } = useWeb3React();
  const [loader , setLoader] = useState(false);

  let data = {

    updateUser: (account) => {
      setSession(account);
    },

    connectWallet: () => {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          activate(injected);
        }
      });
    },

  };

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem("userAddress");
    if (userAddress) {
      data.connectWallet();
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line




  // account balance -----//
// const getUserbalance = async () => {
//   const web3 = await getWeb3Obj();
//   const balance = await web3.eth.getBalance(account);

//   const balanceImETH = await web3.utils.fromWei(balance);
//   setUserWalletBalance(parseFloat(balanceImETH).toFixed(2));
// };

 //-----*WEB3-Connection-Establisher*-----//
//  const connectWalletHandler = () => {
//   try {
//     const connector = injected;
//     localStorage.setItem("walletName", "METAMASK");
//     sessionStorage.removeItem("walletName");
//     if (connector && connector.walletConnectProvider?.wc?.uri) {
//       connector.walletConnectProvider = undefined;
//     }
//     activate(connector, undefined, true).catch((error) => {
//       if (error) {
//         localStorage.removeItem("walletName");
//         // activate(connector);
//         console.log("error", error);
//       }
//     });
//   } catch (error) {
//     console.log("ERROR", error);
//     // toast.error(JSON.stringify(error.message));
//   }
// };

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
